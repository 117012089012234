import Navbar from "../Navbar/Navbar";
import "../NavbarOther/NavbarOther.css";
import vkaystone from "../../assets/company/company.png";
import { NavLink } from "react-router-dom";

const NavbarOther = () => {
  return (
    <>
      <Navbar></Navbar>
      <div className="navbar-menu-container-other">
        <div className="navbar-image">
          <img className="footer-logo" src={vkaystone} alt="Eternal logo" />
        </div>
        <div className="navbar-list">
          <ul className="nav-items">
            <NavLink to="/" className="each-nav-item">
              HOME
            </NavLink>
            <NavLink to="/about" className="each-nav-item">
              ABOUT US
            </NavLink>
            <NavLink to="/StoneSheet" className="each-nav-item">
              STONE VENEER SHEETS
            </NavLink>
            <NavLink
              to="/Stone-Veneer-Manufacturer"
              className="each-nav-item"
            >
              MANUFACTURER
            </NavLink>
          </ul>
        </div>
        <div className="send-enquiry-box">
          <NavLink to="/contact" className="send-enquiry-header">
            CONTACT
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default NavbarOther;
