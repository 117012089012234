// Footer.js

import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import CompanyLogo from "../../assets/company/company.webp";
import "./Footer.css";
// import productdata from "../../StoneSheets.json";
import { NavLink } from "react-router-dom";

const styles = {
  footer: {
    backgroundColor: "#a47335",
    padding: "20px",
    marginTop: "30px",
    textAlign: "center",
  },
  socialIcons: {
    margin: "0 8px",
  },
};

const Footer = () => {
  return (
    <footer className="footer" style={styles.footer}>
      <div className="footer-container">
        <div className="first-block">
          <img className="footer-logo" src={CompanyLogo} alt="vkay logo" />
          <p className="orange-color">
            {" "}
            Transforming spaces with premium-quality stone veneers for lasting
            beauty.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
            }}
          >
            <NavLink to="https://www.facebook.com/profile.php?id=61559794957753">
              <IconButton
                sx={{
                  backgroundColor: "#3B5998",
                  "&:hover": {
                    backgroundColor: "#4267B2",
                  },
                }}
                style={styles.socialIcons}
                color="primary"
                aria-label="Facebook"
                target="_blank"
              >
                <FacebookIcon sx={{ color: "#fff" }} />
              </IconButton>
            </NavLink>
            <NavLink to="https://x.com/vkaystoneveneer">
              <IconButton
                sx={{
                  backgroundColor: "#1DA1F2",
                  "&:hover": {
                    backgroundColor: "#0084B4",
                  },
                }}
                style={styles.socialIcons}
                color="primary"
                aria-label="Twitter"
                target="_blank"
              >
                <TwitterIcon sx={{ color: "#fff" }} />
              </IconButton>
            </NavLink>
            <NavLink to="https://www.instagram.com/vkay_stoneveneer/">
              <IconButton
                sx={{
                  backgroundColor: "#C13584", // Instagram color
                  "&:hover": {
                    backgroundColor: "#e4405f", // Instagram hover color
                  },
                  borderRadius: "50%", // Make the icon circular
                }}
                style={styles.socialIcons}
                color=""
                aria-label="Instagram"
                target="_blank"
              >
                <InstagramIcon sx={{ color: "#fff" }} />
              </IconButton>
            </NavLink>
            <NavLink to="https://wa.me/919828560557">
              <IconButton
                sx={{
                  backgroundColor: "#25d366",
                  "&:hover": {
                    backgroundColor: "#128C7E",
                  },
                }}
                style={styles.socialIcons}
                aria-label="WhatsApp"
                target="_blank"
              >
                <WhatsAppIcon sx={{ color: "#fff" }} />
              </IconButton>
            </NavLink>
          </div>
        </div>
        <div className="second-block">
          <h2 className="orange-color"> StoneSheets</h2>

          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/StoneSheet"
          >
            Marble Stone Veneer
          </NavLink>
          <br></br>
          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/StoneSheet"
          >
            Sandstone Veneer
          </NavLink>
          <br></br>
          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/StoneSheet"
          >
            Slate Stone Veneer
          </NavLink>
          <br></br>
          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/StoneSheet"
          >
            Translucent Stone Veneer
          </NavLink>

          {/* {productdata.map((item, index) => {
            return (
              <div key={index}>
                <NavLink
                  className="footer-product-list orange-color"
                  to={`/StoneSheet/${item.name}`}
                >
                  {" "}
                  {item.name}
                </NavLink>
                <br></br>
              </div>
            );
          })} */}
        </div>
        <div className="third-block">
          <h2 className="orange-color">Company</h2>
          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/"
          >
            Home
          </NavLink>
          <br></br>
          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/StoneSheet"
          >
            StoneSheets
          </NavLink>
          <br></br>
          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/about"
          >
            About Us
          </NavLink>
          <br></br>
          <NavLink
            className="footer-product-list orange-color"
            color="inherit"
            to="/contact"
          >
            Contact Us
          </NavLink>
        </div>
        <div
          className="fourth-block"
          style={{
            textAlign: "center",
            marginTop: "0px",
            color: "#fff",
            fontFamily: "serif",
          }}
        >
          {/* Contact Section */}
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <h2 className="orange-color">Contact Us</h2>

            <Typography
              className="footer-product-list orange-color"
              variant="body1"
              style={{ color: "#fff" }}
            >
              Address: B-3, Bhan Nagar, Queens Road, Jaipur-302021, INDIA.
            </Typography>

            <Typography
              className="footer-product-list orange-color"
              variant="body1"
              style={{ color: "#fff" }}
            >
              Phone:{" "}
              <a href="tel:+917340561155" style={{ color: "#fff" }}>
                +91-7340561155
              </a>
              <br></br>
              Phone:{" "}
              <a href="tel:+919828560557" style={{ color: "#fff" }}>
                +91 9828560557
              </a>
            </Typography>

            <Typography
              className="footer-product-list orange-color"
              variant="body1"
              style={{ color: "#fff" }}
            >
              Email:{" "}
              <a
                href="mailto:vkaystoneveneer@gmail.com"
                style={{ color: "#fff" }}
              >
                vkaystoneveneer@gmail.com
              </a>
            </Typography>
          </div>
        </div>
      </div>
      <Typography
        className="footer-logo-color"
        variant="h6"
        gutterBottom
      ></Typography>

      <Typography
        className="footer-logo-wrapper"
        variant="body2"
        color="textSecondary"
      >
        Copyright &copy; {new Date().getFullYear()} Vkay Stone Veneer. All
        rights reserved.
      </Typography>
    </footer>
  );
};

export default Footer;
