import React from "react";
import { Paper } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import "./Carousel.css";
import img14 from "../../assets/company/black.webp";
import img15 from "../../assets/company/indianautumn.webp";
import img16 from "../../assets/company/oceanblack.webp";
import img17 from "../../assets/company/autumnrustic.webp";
import img18 from "../../assets/company/silviawhite.webp";

import { NavLink } from "react-router-dom";

const exampleItems = [
  {
    name: "Black Stone Veneer",
    url_name: "Black-Stone-Veneer",
    image_url: img14,
    description:
      "Black Veneer Sheets is a very popular slate from northern India. It is also known as Ashen Black Flexible Stone Veneer, Negro Flexible Stone Veneer, Arkobalento Gris Flexible Stone Veneer, Negro Premium Flexible Stone Veneer. ",
  },
  {
    name: "Indian Autumn Stone Veneer",
    url_name: "Indian-Autumn-Stone-Veneer",
    image_url: img15,
    description:
      "Indian Autumn should not be confused with traditional stone veneer. Flexible stone veneers are considered true stone veneers in the same way that wood veneers are very thin sheets of wood. It is used for interior and exterior, especially where bending to a curved surface is required. Flexible stone veneer is made from a thin layer of stone stripped or peeled from a stone slab, rather than cutting from a solid stone or precast composite material. ",
  },
  {
    name: "Ocean Black Stone Veneer",
    url_name: "Ocean-Black-Stone-Veneer",
    image_url: img16,
    description:
      "Ocean Black should not be confused with traditional stone veneer. Flexible stone veneers are considered true stone veneers in the same way that wood veneers are very thin sheets of wood. It is used for interior and exterior, especially where bending to a curved surface is required. Flexible stone veneer is made from a thin layer of stone stripped or peeled from a stone slab, rather than cutting from a solid stone or precast composite material.  ",
  },
  {
    name: "Autumn Rustic Stone Veneer",
    url_name: "Autumn-Rustic-Stone-Veneer",
    image_url: img17,
    description:
      "Autumn Rustic should not be confused with traditional stone veneer. Flexible stone veneers are considered true stone veneers in the same way that wood veneers are very thin sheets of wood. It is used for interior and exterior, especially where bending to a curved surface is required. Flexible stone veneer is made from a thin layer of stone stripped or peeled from a stone slab, rather than cutting from a solid stone or precast composite material. ",
  },
  {
    name: "Silvia White Stone Veneer",
    url_name: "Silvia-White-Stone-Veneer",
    image_url: img18,
    description:
      "Silvia Marble Veneer should not be confused with traditional stone veneer. Flexible stone veneers are considered true stone veneers in the same way that wood veneers are very thin sheets of wood. It is used for interior and exterior, especially where bending to a curved surface is required. Flexible stone veneer is made from a thin layer of stone stripped or peeled from a stone slab, rather than cutting from a solid stone or precast composite material.  ",
  },
];

const CarouselComponent = () => {
  return (
    <Carousel
      animation="slide"
      indicators={true}
      timeout={3000}
      navButtonsAlwaysVisible={false}
      navButtonsAlwaysInvisible={true}
      cycleNavigation={true}
      fullHeightHover={false}
      className="carousel-wrapper"
      sx={{
        flexGrow: 1,
        margin: "20px",
      }}
    >
      {exampleItems.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
};

function Item(props) {
  return (
    <Paper
      sx={{
        backgroundColor: "inherit",
        color: "black",
        justifyContent: "center",
        alignItems: "center",
        height: "400px",
        display: "flex",
        flexDirection: "column",
        p: 4,
        boxShadow: "none",
      }}
    >
      <div className="carousel-container">
        <div>
          <img
            className="carousel-images"
            src={props.item.image_url}
            alt={props.item.name}
          />
        </div>
        <div className="image-description">
          <h3 className="carousel-title">{props.item.name}</h3>
          <p className="paragraph">{props.item.description}</p>
          <div className="view-more-wrapper">
            <NavLink
              to={`/StoneSheet/${props.item.url_name}`}
              className="view-more"
            >
              View Detail
            </NavLink>
          </div>
        </div>
      </div>
    </Paper>
  );
}

export default CarouselComponent;
